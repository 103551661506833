
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 400px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.navbar {
  margin: 0;
  width: 100%;
  height: 80px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

.create-recipe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 400px;
}
.create-recipe form {
  display: flex;
  flex-direction: column;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 10px;
}

h2 {
  margin-top: 0;
}

img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

img {
  width: 400px;
}

p {
  max-width: 400px;
  font-size: 12px;
}
